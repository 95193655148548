import { WithDbId } from "adl-gen/common/db";
import { Product } from "adl-gen/ferovinum/app/db";
import { Schema } from "yup";
import { ProductLineItemData } from "../ui/page/organisation/new-deal-requests/organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { RowIdentifierAndErrors, WithCsvRowNumber } from "utils/csv-utils";
import { CsvRowParser } from "utils/csv-row-parser";
import { isNotNull } from "utils/ts-utils";
import _ from "lodash";

export const PRODUCT_CODE_COLUMN_NAME = "code";
export interface ProcessedCsvProductData {
  products: WithCsvRowNumber<CsvProductLineItemData>[];
  errors: WithCsvRowNumber<RowIdentifierAndErrors>[];
}

export interface ProductCsvParser {
  getProductCode(rowData: Readonly<string>[]): string;
  createNewProduct(rowData: Readonly<string[]>, isNewStock?: boolean): CsvProductLineItemData;
  createExistingProduct(rowData: Readonly<string[]>, existingProduct: WithDbId<Product>): CsvProductLineItemData;

  getErrorMessagesForRow(
    isExistingProduct: boolean,
    rowData: Readonly<string[]>,
    uniqueProductCodes: Set<string>,
  ): string[];
}

export class ColumnSpec<T, Result> {
  public static make<T, Result>(params: {
    name: ColumnSpec<T, Result>["name"];
    schema: ColumnSpec<T, Result>["schema"];
    extract: ColumnSpec<T, Result>["extract"];
  }) {
    const { name, schema, extract } = params;
    return new ColumnSpec(name, schema.label(name), extract);
  }

  private constructor(
    public readonly name: string,
    public readonly schema: Schema<T>,
    public readonly extract: ((result: Result) => T | undefined) | "not-product-data",
  ) {
    this.schema.label(name);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FirstParameter<T> = T extends ColumnSpec<infer A, any> ? A : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ColumnData<Spec extends Record<string, ColumnSpec<any, any>>> {
  constructor(
    private readonly spec: Spec,
    private readonly columnIdx: Readonly<Record<keyof Spec, number>>,
    private readonly columnData: Readonly<string[]>,
  ) {}

  getRaw<K extends keyof Spec>(key: K): string {
    return this.columnData[this.columnIdx[key]];
  }

  get<K extends keyof Spec>(key: K): FirstParameter<Spec[K]> {
    const raw = this.getRaw(key);
    return this.spec[key].schema.cast(raw);
  }

  validateAndGet<K extends keyof Spec>(key: K): FirstParameter<Spec[K]> | undefined {
    const raw = this.columnData[this.columnIdx[key]];
    const { schema } = this.spec[key];
    return schema.isValidSync(raw) ? schema.cast(raw) : undefined;
  }
}

export interface CsvDataField {
  key: string;
  value: string;
  isMismatched: boolean;
}

export type CsvProductLineItemData = ProductLineItemData & {
  csvDataFields?: CsvDataField[];
  hasMismatchedDetails?: boolean;
};

export function numberOrUndefined(v: string | undefined) {
  if (v === undefined || v.length === 0) {
    return undefined;
  }
  return Number(v);
}

export function createCsvDataFields<T extends object>(
  rowData: Readonly<string[]>,
  existingData: Partial<T>,
  csvParser: CsvRowParser<T>,
): CsvDataField[] {
  return csvParser
    .mapRowDataToArray(rowData, (inputStr, key, def) => {
      const trimmedInput = typeof inputStr === "string" ? inputStr.trim() : true;
      if (trimmedInput) {
        try {
          const inputVal = def.validate(inputStr);
          if (inputVal != undefined) {
            return {
              key,
              value: inputStr,
              isMismatched: !_.isEqual(inputVal, existingData[key]),
            } as CsvDataField;
          }
        } catch {}
      }
      return null;
    })
    .filter(isNotNull);
}
