/* eslint-disable @typescript-eslint/no-explicit-any */
// We are doing some pretty complicated type conversations here, need to use 'any' here and there
// to make it work.
import { LocalDate, LocalTime } from "adl-gen/common";
import { PartialDate } from "adl-gen/ferovinum/app/db";
import { camelCase, startCase } from "lodash";
import {
  formatDateToLocalDate,
  isFullDateString,
  isLocalDate,
  isLocalTime,
  isPartialDate,
  parsePartialDate,
} from "utils/date-utils";
import {
  array,
  boolean,
  mixed,
  number,
  string,
  object,
  Schema,
  ObjectSchema,
  ArraySchema,
  ObjectShape,
  Flags,
} from "yup";
import { DataFieldDef, FieldMetaData } from "./data-field-def";
import { isObject, keysOf } from "../type-utils";
import { ObjectFields, makeObjectSchema } from "./object-field-def";
import { UnionType } from "../utility-types";
import { parsePrice } from "../currency-utils";

type MessageParams = { label: string; path: string; value: unknown; originalValue: unknown; type: string };
type ErrorMessageFn<T extends object = object> = (params: Partial<MessageParams> & T) => string;

export const requiredErrorHandler: ErrorMessageFn = ({ label }) => `${label} is missing.`;

export const typeErrorHandler: ErrorMessageFn = ({ label, originalValue, type }) =>
  `${label} must be a ${type}, '${formatUnknownValue(originalValue)}' is invalid.`;

export function makeEnumErrorHandler(valuesStrings: string[]): ErrorMessageFn {
  return ({ label, originalValue }) => {
    const allValuesStr = valuesStrings.join(", ");
    return `${label} can only be: ${allValuesStr}. '${formatUnknownValue(originalValue)}' is invalid.`;
  };
}

export function makePrecisionErrorHandler(precision: number): ErrorMessageFn {
  return ({ label, originalValue }) =>
    `${label} must have at most ${precision} decimal places, '${formatUnknownValue(originalValue)}' is invalid.`;
}

function formatUnknownValue(value: unknown): string {
  return typeof value === "string" ? `${value.trim()}` : `${value}`;
}

type SchemaType<T> = Schema<T | undefined, any, T | undefined, Flags>;

// Given a particular type definition, the DataFieldBuilder can create 'required', 'optional' and 'nullable'
// variations of that type.
// The 'required' variation cannot be null or undefined and will throw an error if the value is missing.
// The 'optional' variation can be undefined and will return undefined if the value is missing.
// The 'nullable' variation can be null and will return null if the value is missing.
// This class is not exported, instead it is created (and configured) through the type-specific functions below
// (e.g. stringField, numberField, etc).
class DataFieldBuilder<T> {
  private readonly label: string;
  private readonly toString: (v?: T) => string | undefined;
  readonly metaData: FieldMetaData;
  // TODO Zhi: SIMPLIFY
  // See comment in data-field-def.ts
  private readonly baseSchema: SchemaType<T>;
  private schemaWithNonTrivialValidation?: SchemaType<T>;

  constructor(params: {
    label: string;
    schema: SchemaType<T>;
    typeCheck(o: any): o is T;
    parse(s: string): T | undefined;
    toString(v?: T): string | undefined;
    metaData: FieldMetaData;
  }) {
    this.label = params.label;
    this.toString = params.toString;
    this.metaData = Object.freeze(params.metaData);
    this.baseSchema = params.schema.transform((v, ov) => {
      if (params.typeCheck(v)) {
        return v;
      } else if (typeof ov !== "string" || !ov.trim()) {
        return undefined; // filter out empty string and non-strings
      } else {
        return params.parse(ov) ?? "$$INVALID_VALUE$$";
      }
    });
  }

  updateSchemaWithNonTrivialValidation(updateFn: (schema: SchemaType<T>) => SchemaType<T>) {
    this.schemaWithNonTrivialValidation = updateFn(this.schemaWithNonTrivialValidation ?? this.baseSchema);
    return this;
  }

  required() {
    return new DataFieldDef<T>(
      this.label,
      this.baseSchema.required(requiredErrorHandler),
      this.schemaWithNonTrivialValidation?.required(requiredErrorHandler),
      v => this.toString(v),
      this.metaData,
      true,
    );
  }

  optional() {
    return new DataFieldDef<T | undefined>(
      this.label,
      this.baseSchema.optional(),
      this.schemaWithNonTrivialValidation?.optional(),
      v => (v === undefined ? "" : this.toString(v)),
      this.metaData,
      false,
    );
  }

  nullable() {
    return new DataFieldDef<T | null>(
      this.label,
      this.baseSchema.nullable().default(null),
      this.schemaWithNonTrivialValidation?.nonNullable().nullable().default(null),
      v => (v === null ? "" : this.toString(v)),
      this.metaData,
      false,
    );
  }
}

// Creates a DataFieldBuilder for string fields.
export function stringField(
  label: string,
  params?: {
    range: { min?: number; max?: number };
    updateSchema?: (schema: Schema<string>) => Schema<string>;
  },
): DataFieldBuilder<string> {
  const { range = {}, updateSchema } = params ?? {};
  let basicSchema = string().trim();

  if (range.min !== undefined) {
    basicSchema = basicSchema.min(range.min, `${label} must be at least ${range.min}.`);
  }
  if (range.max !== undefined) {
    basicSchema = basicSchema.max(range.max, `${label} cannot exceed ${range.max}.`);
  }

  const finalSchema = updateSchema ? updateSchema(basicSchema as Schema<string>) : basicSchema;
  return new DataFieldBuilder<string>({
    label,
    typeCheck: (o): o is string => typeof o === "string" && o.trim().length > 0,
    parse: s => s.trim(),
    toString: v => v,
    schema: finalSchema,
    metaData: { kind: "string" },
  });
}

export function booleanField(label: string): DataFieldBuilder<boolean> {
  return new DataFieldBuilder<boolean>({
    label,
    typeCheck: (o): o is boolean => typeof o === "boolean",
    parse: s => {
      switch (s.trim().toLowerCase()) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return undefined;
      }
    },
    toString: v => v?.toString(),
    schema: boolean().typeError(typeErrorHandler),
    metaData: { kind: "boolean" },
  });
}

export function arrayField<E>(
  label: string,
  elementDef: DataFieldDef<E>,
  params?: {
    updateSchema?: (schema: ArraySchema<E[] | undefined, any>) => Schema<E[]>;
  },
): DataFieldBuilder<E[]> {
  const elementSchema = elementDef.getSchema();
  const schema = array().of(elementSchema).default(undefined).typeError(typeErrorHandler);
  return new DataFieldBuilder<E[]>({
    label,
    typeCheck: (o): o is E[] => Array.isArray(o),
    parse: s => {
      if (s.trim()) {
        try {
          return JSON.parse(s.trim()) as E[];
        } catch {
          return undefined;
        }
      }
      return [];
    },
    toString: v => (v ? JSON.stringify(v) : undefined),
    schema: params?.updateSchema ? params.updateSchema(schema) : schema,
    metaData: { kind: "array", elementDef: elementDef as DataFieldDef<unknown> },
  });
}

// Creates a DataFieldBuilder for number fields.
// The optional 'params' argument can be used to specify valid range of the number,
// more params could be added in the future.
export function numberField(
  label: string,
  params?: {
    range?: { min?: number; max?: number } | "positive" | "negative";
    precision?: number;
    ignoreCurrency?: boolean;
    updateSchema?: (schema: Schema<number>) => Schema<number>;
  },
): DataFieldBuilder<number> {
  const { range, precision } = params ?? {};
  let schema = number()
    .test(
      "finite-check",
      ({ label, value }) => `${label} must be a finite number, '${value}' is invalid.`,
      v => v === undefined || isFinite(v),
    )
    .typeError(typeErrorHandler);
  if (range === "positive") {
    schema = schema.positive(({ label, value }) => `${label} must be a positive number, '${value}' is invalid.`);
  } else if (range === "negative") {
    schema = schema.negative(({ label, value }) => `${label} must be a negative number, '${value}' is invalid.`);
  } else {
    if (range?.min !== undefined) {
      schema = schema.min(
        range.min,
        ({ label, value, min }) => `${label} cannot be below ${min}, '${value}' is invalid.`,
      );
    }
    if (range?.max !== undefined) {
      schema = schema.max(
        range.max,
        ({ label, value, max }) => `${label} cannot exceed ${max}, '${value}' is invalid.`,
      );
    }
  }
  if (precision !== undefined && precision >= 0) {
    schema = schema.test("precision-check", makePrecisionErrorHandler(precision), v => {
      if (v === undefined) {
        return true;
      }
      const [, decimalPart] = v.toString().split(".");
      return decimalPart === undefined || decimalPart.length <= precision;
    });
  }

  return new DataFieldBuilder<number>({
    label,
    typeCheck: (o): o is number => {
      return typeof o === "number" && isFinite(o) && !isNaN(o);
    },
    parse: s => {
      if (params?.ignoreCurrency) {
        const price = parsePrice(s);
        return price ?? undefined;
      }

      return s.trim() ? Number(s.trim()) : undefined;
    },
    toString: v => v?.toString(),
    schema: params?.updateSchema?.(schema as Schema<number>) ?? schema,
    metaData: { kind: "number", range: params?.range, precision: params?.precision },
  });
}

export type UnionFieldDefMapping<U extends UnionType> = {
  [E in U as E["kind"]]: E extends { value: any } ? DataFieldDef<E["value"]> : "no-value";
};

export function unionField<U extends UnionType>(
  label: string,
  defMapping: UnionFieldDefMapping<U>,
): DataFieldBuilder<U> {
  const resolveValSchema = (obj: any) => {
    if (isObject(obj)) {
      if (typeof obj.kind === "string" && obj.kind in defMapping) {
        const kind = obj.kind as U["kind"];
        const valDef = defMapping[kind];
        const schema = valDef !== "no-value" ? (valDef as DataFieldDef<unknown>).getSchema() : undefined;
        return { kind, value: obj.value, schema, typeValid: true };
      }
    }
    return { typeValid: false };
  };
  const parse: (s: string) => U | undefined = (s: string) => {
    const obj = checkedParseJson(s);
    const res = resolveValSchema(obj);
    if (!res?.typeValid) {
      return undefined;
    } else if (res.schema) {
      const value = res.schema.validateSync(res.value);
      return { kind: res.kind, value } as U;
    } else {
      // include res.value here so it can be picked up by the 'no-value' check below.
      return { kind: res.kind, value: res.value } as U;
    }
  };
  const kindValues = keysOf(defMapping);
  let valueSchema = mixed();
  for (const kind of kindValues) {
    valueSchema = valueSchema.when("kind", {
      is: kind,
      then: schema => {
        const valueDef = defMapping[kind];
        if (valueDef == "no-value") {
          return schema.test(
            "no-value",
            `'value' should not be present when 'kind' is '${kind.toString()}'`,
            value => value === undefined,
          );
        } else {
          return (valueDef as DataFieldDef<unknown>).getSchema();
        }
      },
    });
  }
  const kindSchema: Schema<U["kind"]> = string().oneOf(kindValues).required();
  const unionShape: ObjectShape = { kind: kindSchema, value: valueSchema };
  const schema = object(unionShape).typeError(params =>
    typeErrorHandler({ ...params, type: "Union" }),
  ) as ObjectSchema<U>;

  return new DataFieldBuilder<U>({
    label,
    schema: schema.default(undefined) as SchemaType<U>,
    typeCheck: (o: any): o is U => resolveValSchema(o).typeValid,
    parse,
    toString: v => (v ? JSON.stringify(v) : undefined),
    metaData: { kind: "union", defMapping },
  });
}

export function objectField<T extends object>(label: string, fieldDefs: Readonly<ObjectFields<T>>) {
  const schema = makeObjectSchema(fieldDefs).typeError(params =>
    typeErrorHandler({ ...params, type: "Object" }),
  ) as ObjectSchema<T>;
  const typeCheck = (obj: any): obj is T => {
    return isObject(obj);
  };
  const parse = (s: string) => {
    const obj = checkedParseJson(s);
    return isObject(obj) ? (schema.validateSync(obj) as T) : undefined;
  };
  const toString = (v: T) => JSON.stringify(v);
  const schemaWithDefault = schema.default(undefined) as SchemaType<T>;
  return new DataFieldBuilder<T>({
    label,
    schema: schemaWithDefault,
    typeCheck,
    parse,
    toString,
    metaData: { kind: "object", fieldDefs },
  });
}

function checkedParseJson(s: string): unknown {
  try {
    return JSON.parse(s);
  } catch {
    return undefined;
  }
}

// Creates a DataFieldBuilder for enum fields.
// The 'values' argument is an array of valid values for the enum.
// The optional 'transform' argument can be used to specify custom parsing and stringifying functions.
export function enumField<T extends string>(
  label: string,
  values: Readonly<T[]>,
  transform?: {
    parse: (s: string) => T | undefined;
    toString: (v: T) => string;
  },
): DataFieldBuilder<T> {
  const parse = transform?.parse ?? (s => values.find(v => camelCase(v) === camelCase(s.trim())));
  const toString = transform?.toString ?? startCase;
  const valueStrings = values.map(v => (toString ? toString(v) : startCase(v)));
  const typeCheck: (o: any) => o is T = (o): o is T => values.includes(o as T);
  const schema: Schema<T | undefined> = string<T>()
    .trim()
    .test("value-check", makeEnumErrorHandler(valueStrings), v => !v || typeCheck(v));
  return new DataFieldBuilder<T>({ label, typeCheck, schema, parse, toString, metaData: { kind: "enum", values } });
}

// Creates a DataFieldBuilder for LocalDate fields.
export function localDateField(
  label: string,
  params?: {
    range?: { min?: LocalDate; max?: LocalDate };
  },
): DataFieldBuilder<LocalDate> {
  const { range } = params ?? {};
  let schema = mixed<LocalDate>({ type: "Date", check: isLocalDate }).typeError(typeErrorHandler);
  if (range?.min) {
    const minDate = range.min;
    schema = schema.test(
      "min-check",
      ({ label, value }) => `${label} cannot be before ${minDate}, '${value}' is invalid.`,
      v => v === undefined || new Date(v).getTime() >= new Date(minDate).getTime(),
    );
  }
  if (range?.max) {
    const maxDate = range.max;
    schema = schema.test(
      "max-check",
      ({ label, value }) => `${label} cannot be after ${maxDate}, '${value}' is invalid.`,
      v => v === undefined || new Date(v).getTime() <= new Date(maxDate).getTime(),
    );
  }
  return new DataFieldBuilder<LocalDate>({
    label,
    typeCheck: isLocalDate,
    parse: s => {
      const date = isFullDateString(s) ? new Date(s) : undefined;
      return date && !isNaN(date.getTime()) ? formatDateToLocalDate(date) : undefined;
    },
    toString: v => v,
    schema,
    metaData: { kind: "date", range: params?.range },
  });
}

// Creates a DataFieldBuilder for PartialDate fields.
export function partialDateField(label: string): DataFieldBuilder<PartialDate> {
  return new DataFieldBuilder<PartialDate>({
    label,
    typeCheck: isPartialDate,
    parse: parsePartialDate,
    toString: v => v?.value,
    schema: mixed<PartialDate>({ type: "(Partial) Date", check: isPartialDate }).typeError(typeErrorHandler),
    metaData: { kind: "partialDate" },
  });
}

// Creates a DataFieldBuilder for LocalDate fields.
export function localTimeField(label: string): DataFieldBuilder<LocalTime> {
  return new DataFieldBuilder<LocalTime>({
    label,
    typeCheck: isLocalTime,
    parse: s => (isLocalTime(s) ? s : undefined),
    toString: v => v,
    schema: mixed<LocalTime>({ type: "LocalTime", check: isLocalTime }).typeError(typeErrorHandler),
    metaData: { kind: "time" },
  });
}

// A staticValue is a DataFieldDef that always returns the same value and is always valid
// It is useful when a property in the data structure needs to be set to a constant value
export const STATIC_FIELD_TYPE = "static-field";
export function staticValue<T>(value: T): DataFieldDef<T> {
  const mixedSchema = mixed({ type: STATIC_FIELD_TYPE, check: alwaysTrue }).transform(() => value) as Schema<T>;
  const schema: Schema<T> = value === null ? mixedSchema.nullable().default(null) : mixedSchema;
  return new DataFieldDef<T>(STATIC_FIELD_TYPE, schema, undefined, () => "", { kind: "static" }, false);
}

function alwaysTrue<T>(_: unknown): _ is T {
  return true;
}

export function anyValueField<T extends object | string | number | boolean>(label: string): DataFieldBuilder<T> {
  return new DataFieldBuilder<T>({
    label,
    typeCheck: alwaysTrue,
    parse: _ => undefined,
    toString: v => String(v),
    schema: mixed<T>().typeError(typeErrorHandler),
    metaData: { kind: "any" },
  });
}
``;
