import {
  Alert,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { NominatedPurchaserDetailsView, ThirdPartySalesSummaryResp } from "adl-gen/ferovinum/app/api";
import { Currency, PurchaseRequest, PurchaseRequestState, Purchaser, PurchaserId } from "adl-gen/ferovinum/app/db";
import {
  OrgPurchaseRequestSummary,
  OrgRejectedPurchaseRequestSummary,
  PurchaseRequestLineItem,
  PurchaseRequestRejectedLineItem,
} from "adl-gen/ferovinum/app/nompurchaser";
import { FEROVINUM_CONTACT_URL } from "components/assets/url";
import { ButtonWithDatePickerDialog } from "components/widgets/buttons/button-with-date-picker-dialog";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Dropdown } from "components/widgets/dropdown/dropdown";
import { InfoField } from "components/widgets/info-field/info-field";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { Link } from "components/widgets/link/link";
import { Loader } from "components/widgets/loader/loader";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { formatDate, formatDateToLocalDate } from "utils/date-utils";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { assertNotUndefined } from "utils/hx/util/types";
import { LoadingValue, isLoaded, isLoading } from "utils/utility-types";
import { AppRoutes } from "../../../../../app/app-routes";
import { NominatedPurchaserIsConfiguredValues } from "../../../../../hooks/use-nominated-purchaser-is-configured";
import {
  getPurchaseRequestStateDescription,
  isPurchaseRequestFinalState,
} from "../../../../../utils/purchase-request-utils";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { ColGroup } from "../../../../widgets/colgroup";
import { ActionableSummaryCard } from "../../../../widgets/common/actionable-summary-card/actionable-summary-card";
import { OrganisationProductSummary } from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import { PurchaseRequestFlowState } from "../organisation-purchase-request-setup/organisation-purchase-request-setup-page";
import { PurchaseRequestAdminAction } from "./organisation-purchase-requests-by-purchaser-page";

export interface OrganisationPurchaseRequestsPageViewProps {
  isAdmin: boolean;
  activeNominatedPurchaser?: PurchaserId;
  purchaseRequests: LoadingValue<WithDbId<PurchaseRequest>[]>;
  nominatedPurchasers: NominatedPurchaserDetailsView[];
  nominatedPurchaserConfig: NominatedPurchaserIsConfiguredValues;
  onChangeNominatedPurchaser(id: DbKey<Purchaser>): void;
  onMarkReadyForCollection(id: DbKey<PurchaseRequest>): Promise<void>;
  onAdminAction(id: DbKey<PurchaseRequest>, action: PurchaseRequestAdminAction): Promise<void>;
  getSummaryForPurchaseRequest(id: DbKey<PurchaseRequest>): Promise<ThirdPartySalesSummaryResp>;
}
export const OrganisationPurchaseRequestsPageView = ({
  isAdmin,
  activeNominatedPurchaser,
  purchaseRequests,
  nominatedPurchasers,
  nominatedPurchaserConfig,
  onChangeNominatedPurchaser,
  onMarkReadyForCollection,
  onAdminAction,
  getSummaryForPurchaseRequest,
}: OrganisationPurchaseRequestsPageViewProps) => {
  const history = useHistory();
  const { isConfigured, nominatedPurchaserFee, defaultMonthlyFee } = nominatedPurchaserConfig;
  const selectedNominatedPurchaser = nominatedPurchasers.find(p => p.nominatedPurchaserId === activeNominatedPurchaser);

  const nominatedPurchaserNotConfiguredText = useMemo(() => {
    if (isAdmin) {
      const invalidFees: string[] = [];
      !nominatedPurchaserFee && invalidFees.push("nominated purchaser fee");
      !defaultMonthlyFee && invalidFees.push("default monthly fee");
      return `Please set up the ${invalidFees.join(" and ")}.`;
    }
    return "";
  }, [isAdmin, nominatedPurchaserFee, defaultMonthlyFee]);
  return (
    <PortalPageContent header={<PortalPageContentHeader title="Third Party Sales" />}>
      {isConfigured ? (
        <>
          {nominatedPurchasers.length > 0 && selectedNominatedPurchaser ? (
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="start">
                <Dropdown<NominatedPurchaserDetailsView>
                  inputLabel=""
                  defaultValue={{
                    label: selectedNominatedPurchaser.name,
                    value: selectedNominatedPurchaser,
                  }}
                  menuItems={nominatedPurchasers.map(p => ({ label: p.name, value: p }))}
                  onChange={v => onChangeNominatedPurchaser(assertNotUndefined(v).nominatedPurchaserId)}
                />
                <Button
                  onClick={() => {
                    const initialFlowState: PurchaseRequestFlowState = {
                      setup: {
                        purchaser: { ...selectedNominatedPurchaser },
                        dealDiscountAmount: undefined,
                        products: [],
                        bondedSale: true,
                        salePriceType: "exDutyAndVat",
                      },
                    };
                    history.push(AppRoutes.OrganisationCreatePurchaseRequest, initialFlowState);
                  }}>
                  Create new sale for this purchaser
                </Button>
              </Stack>
              <CreditLimitInfo {...selectedNominatedPurchaser} />
              <Loader loadingStates={[purchaseRequests]}>
                {isLoaded(purchaseRequests) && purchaseRequests.value.length === 0 ? (
                  <Typography variant="body1" color="common.grey7">
                    You have no third party sale orders.
                  </Typography>
                ) : (
                  <Stack spacing={5}>
                    {isLoaded(purchaseRequests) &&
                      purchaseRequests.value.map(pr => (
                        <PurchaseRequestDetails
                          key={pr.id}
                          isAdmin={isAdmin}
                          purchaseRequest={pr}
                          onMarkReadyForCollection={onMarkReadyForCollection}
                          onAdminAction={onAdminAction}
                          getSummaryForPurchaseRequest={getSummaryForPurchaseRequest}
                        />
                      ))}
                  </Stack>
                )}
              </Loader>
            </Stack>
          ) : (
            <Typography variant="body1" color="common.grey7">
              You have no purchasers
            </Typography>
          )}
        </>
      ) : (
        <Stack spacing={1}>
          <Typography variant="body1" color="common.grey7">
            Your account is not yet set up to create a third party sale order.
          </Typography>
          {isAdmin ? (
            <Typography variant="body1" color="common.grey7">
              {nominatedPurchaserNotConfiguredText}
            </Typography>
          ) : (
            <Stack direction="row" spacing={1}>
              <Link variant="big" color="grey5" href={FEROVINUM_CONTACT_URL} target="_blank">
                Please reach out to Ferovinum
              </Link>
              <Typography>if you would like to be set up with the nominated purchase product.</Typography>
            </Stack>
          )}
        </Stack>
      )}
    </PortalPageContent>
  );
};

interface CreditLimitInfoProps {
  currency: Currency;
  creditLimit: string;
  outstandingCredit: string;
  availableCredit: string;
}
const CreditLimitInfo = ({ currency, creditLimit, outstandingCredit, availableCredit }: CreditLimitInfoProps) => {
  return (
    <Stack spacing={1}>
      <TextWithInfo
        label="Overall payment terms credit limit for this purchaser"
        tooltipText="This is the current limit for the maximum outstanding receivable amount at any point in time from payment terms given on previous sales to this purchaser."
        priceText={creditLimit}
        currency={currency}
      />
      <TextWithInfo
        label="Utilisation from sales with payments that remain outstanding"
        tooltipText="This is the outstanding receivables on previous sales."
        priceText={outstandingCredit}
        currency={currency}
      />
      <TextWithInfo
        label="Remaining credit limit for additional sales"
        tooltipText="This is the remaining available limit after outstanding receivables on previous sales are deducted from the total limit. As this purchaser makes payments on your previous sales, more of the limit will become available."
        priceText={availableCredit}
        currency={currency}
      />
    </Stack>
  );
};
interface PurchaseRequestDetailsProps {
  isAdmin: boolean;
  purchaseRequest: WithDbId<PurchaseRequest>;
  getSummaryForPurchaseRequest(id: DbKey<PurchaseRequest>): Promise<ThirdPartySalesSummaryResp>;
  onMarkReadyForCollection(id: DbKey<PurchaseRequest>): Promise<void>;
  onAdminAction(id: DbKey<PurchaseRequest>, action: PurchaseRequestAdminAction): Promise<void>;
}
const PurchaseRequestDetails = ({
  isAdmin,
  purchaseRequest,
  getSummaryForPurchaseRequest,
  onMarkReadyForCollection,
  onAdminAction,
}: PurchaseRequestDetailsProps) => {
  const isInFinalState = isPurchaseRequestFinalState(purchaseRequest.value.state);

  const [loadingSummary, refreshSummary] = useLoadingDataState(
    useCallback(
      () => getSummaryForPurchaseRequest(purchaseRequest.id),
      [getSummaryForPurchaseRequest, purchaseRequest.id],
    ),
  );

  const handleMarkReadyForCollection = useCallback(async () => {
    await onMarkReadyForCollection(purchaseRequest.id);
    await refreshSummary();
  }, [purchaseRequest, onMarkReadyForCollection, refreshSummary]);

  const handleAdminAction = useCallback(
    async (action: PurchaseRequestAdminAction) => {
      await onAdminAction(purchaseRequest.id, action);
      await refreshSummary();
    },
    [purchaseRequest, onAdminAction, refreshSummary],
  );

  const showCardActions = useMemo(() => {
    const showStates: PurchaseRequestState[] = [
      "readyForCollection",
      "collected",
      "purchaserInvoiced",
      "purchaserPaid",
    ];
    return (
      purchaseRequest.value.state === "purchaserAccepted" ||
      purchaseRequest.value.state === "productionOrderComplete" ||
      (isAdmin && showStates.includes(purchaseRequest.value.state))
    );
  }, [isAdmin, purchaseRequest.value.state]);

  const events = useMemo(() => {
    const { stateEvents } = purchaseRequest.value;
    return {
      readyForCollectionEvent: stateEvents.find(events => events.state === "readyForCollection"),
      collectedEvent: stateEvents.find(events => events.state === "collected"),
      purchaserInvoicedEvent: stateEvents.find(events => events.state === "purchaserInvoiced"),
    };
  }, [purchaseRequest]);

  // It can be marked as ready for collection if it has been accepted by the purchaser and there is no production order
  // or if the linked production order is complete
  const canBeMarkedAsReadyForCollection =
    (purchaseRequest.value.state === "purchaserAccepted" && purchaseRequest.value.productionOrderId === null) ||
    purchaseRequest.value.state === "productionOrderComplete";

  const collectedDate = purchaseRequest.value.collectedDate ?? events.collectedEvent?.time;

  return (
    <ActionableSummaryCard
      isInitiallyExpanded={!isInFinalState && isLoaded(loadingSummary)}
      expandText="Products in this purchase request"
      cardContent={
        isLoaded(loadingSummary) && (
          <ProductsTable
            summary={loadingSummary.value}
            settlementCurrency={purchaseRequest.value.settlementCurrency}
            purchaserCurrency={purchaseRequest.value.purchaserCurrency}
          />
        )
      }
      isLoading={isLoading(loadingSummary)}
      title={purchaseRequest.value.purchaseRequestNumber}
      titleHref={`${AppRoutes.OrganisationPurchaseRequestDetails}/${purchaseRequest.id}`}
      cardActions={
        showCardActions ? (
          <>
            {canBeMarkedAsReadyForCollection && (
              <LoadingActionButton color="secondary" onClick={handleMarkReadyForCollection}>
                Mark as ready for collection
              </LoadingActionButton>
            )}

            {isAdmin && (
              <>
                {purchaseRequest.value.state === "readyForCollection" && (
                  <ButtonWithDatePickerDialog
                    buttonLabel="Mark as collected"
                    color="secondary"
                    dateFieldLabel="Collected at"
                    minDate={events.readyForCollectionEvent && new Date(events.readyForCollectionEvent.time)}
                    maxDate={new Date()}
                    onConfirm={date =>
                      handleAdminAction({ action: "markCollected", collectedAt: formatDateToLocalDate(date) })
                    }
                  />
                )}
                {purchaseRequest.value.state === "collected" &&
                  purchaseRequest.value.purchaseRequestDeliveryOption.kind === "deliveryToNominatedPurchaser" && (
                    <ButtonWithDatePickerDialog
                      buttonLabel="Mark as delivered"
                      color="secondary"
                      dateFieldLabel="Delivered at"
                      minDate={collectedDate ? new Date(collectedDate) : undefined}
                      maxDate={new Date()}
                      onConfirm={date =>
                        handleAdminAction({ action: "markDelivered", deliveredAt: formatDateToLocalDate(date) })
                      }
                    />
                  )}
                {purchaseRequest.value.state === "purchaserInvoiced" && (
                  <ButtonWithDatePickerDialog
                    buttonLabel="Purchaser has paid"
                    color="secondary"
                    onConfirm={date => handleAdminAction({ action: "markPurchaserPaid", paidAt: date, fxRate: null })}
                    dialogTitle="Purchase Paid State Information"
                    dateFieldLabel="When did the purchaser pay?"
                    minDate={events.purchaserInvoicedEvent && new Date(events.purchaserInvoicedEvent.time)}
                    maxDate={new Date()}
                  />
                )}
                {purchaseRequest.value.state === "purchaserPaid" && (
                  <Alert severity="info">You can mark organisation as paid in Admin Portal - Treasury</Alert>
                )}
              </>
            )}
          </>
        ) : undefined
      }
      headerContent={<PurchaseRequestDetailsHeader purchaseRequest={purchaseRequest.value} />}
    />
  );
};

interface PurchaseRequestDetailsHeaderProps {
  purchaseRequest: PurchaseRequest;
}
const PurchaseRequestDetailsHeader = ({
  purchaseRequest: {
    stateEvents,
    collectionDays,
    purchaseRequestDeliveryOption,
    purchaserPaidDate,
    orgNetReceivablePaymentDate,
    collectedDate,
    deliveredDate,
  },
}: PurchaseRequestDetailsHeaderProps) => {
  const stateText = getPurchaseRequestStateDescription({
    stateEvents,
    collectionDays,
    purchaseRequestDeliveryOption,
    purchaserPaidDate,
    orgNetReceivablePaymentDate,
    collectedDate,
    deliveredDate,
  });

  const createdAt = formatDate(new Date(stateEvents[0].time));
  return (
    <Grid container direction={"row"}>
      <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
        <InfoField sx={{ alignItems: "flex-end" }} label="Created on" value={createdAt} textAlign="right" />
      </Grid>
      <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
        <Typography color="common.grey6" sx={{ alignItems: "flex-end" }} variant="subtitle1Bold">
          {stateText ?? ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface ProductsTableProps {
  summary: ThirdPartySalesSummaryResp;
  settlementCurrency: Currency;
  purchaserCurrency: Currency;
}

const ProductsTable = ({ summary, settlementCurrency, purchaserCurrency }: ProductsTableProps) => {
  let summaryValue: OrgPurchaseRequestSummary | OrgRejectedPurchaseRequestSummary;
  switch (summary.kind) {
    case "success":
      summaryValue = summary.value as OrgPurchaseRequestSummary;
      break;
    case "rejectedOrExpired":
      summaryValue = summary.value as OrgRejectedPurchaseRequestSummary;
      break;
    default:
      throw new Error("Unable to fetch third party sale summary");
  }

  const isSuccessType = summary.kind === "success";

  const hasFreeUnit = summaryValue.lineItems.some(li => li.freeUnits != null);
  const hasDiscount = summaryValue.lineItems.some(li => li.priceDiscount != null);

  return (
    <>
      <Table>
        <ColGroup widths={[...[50, 20, 20, 20], ...(isSuccessType ? [20] : []), ...(hasDiscount ? [20] : [])]} />
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="center">Quantity</TableCell>
            {isSuccessType && (
              <TableCell align="center">
                Average Net Payable
                <br />
                Per Unit {hasFreeUnit ? "(Incl. Free)" : ""}
              </TableCell>
            )}
            <TableCell align="center">
              Sale Price Per{hasFreeUnit ? " Paid " : " "}Unit
              <br />
              (Ex Duty)
            </TableCell>
            {hasDiscount && (
              <TableCell align="center">
                Unit Discount Included
                <br />
                <Typography variant="caption">% of unit price</Typography>
              </TableCell>
            )}
            <TableCell align="right">Subtotal</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {summaryValue.lineItems.map(lineItem => {
            const subtotal = Number(lineItem.salePricePerPaidUnit) * lineItem.paidUnits.value;
            return (
              <TableRow key={lineItem.product.id}>
                <TableCell>
                  <OrganisationProductSummary productId={lineItem.product.id} {...lineItem.product.value} />
                </TableCell>
                <TableCell align="center">
                  <Typography>{lineItem.paidUnits.value}</Typography>
                  {lineItem.freeUnits && <Typography variant="caption">+{lineItem.freeUnits.value} free</Typography>}
                </TableCell>
                {isSuccessType && (
                  <TableCell align="center">
                    {isPurchaseRequestLineItem(lineItem) ? (
                      <CurrencyRenderer
                        maximumFractionDigits={4}
                        value={lineItem.averageNetPayablePerUnit}
                        currency={settlementCurrency}
                      />
                    ) : (
                      "-error-"
                    )}
                  </TableCell>
                )}
                <TableCell align="center">
                  <CurrencyRenderer
                    maximumFractionDigits={4}
                    value={lineItem.cleanPricePerPaidUnit}
                    currency={purchaserCurrency}
                  />
                </TableCell>
                {hasDiscount && (
                  <TableCell align="center">
                    {lineItem.priceDiscount ? (
                      <CurrencyRenderer
                        maximumFractionDigits={4}
                        value={lineItem.priceDiscount}
                        currency={purchaserCurrency}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                )}
                <TableCell align="right">
                  <CurrencyRenderer maximumFractionDigits={4} value={subtotal} currency={purchaserCurrency} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {summaryValue.dealDiscountAmount && (
        <Stack
          direction="row"
          sx={{ backgroundColor: "common.grey1" }}
          alignItems="center"
          justifyContent="flex-end"
          p={2}
          spacing={5}>
          <TextWithInfo
            label={"Deal Discount"}
            tooltipText={"Discount applied to the total amount owed by the purchaser"}
            priceText={summaryValue.dealDiscountAmount}
            currency={purchaserCurrency}
          />
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{ backgroundColor: "common.grey1" }}
        alignItems="center"
        justifyContent="flex-end"
        p={2}
        spacing={5}>
        <TextWithInfo
          label={"Net Subtotal"}
          tooltipText={"Total amount owed by the purchaser"}
          boldLabel
          priceText={summaryValue.netSubtotal}
          currency={purchaserCurrency}
        />
      </Stack>
    </>
  );
};

function isPurchaseRequestLineItem(
  value: PurchaseRequestLineItem | PurchaseRequestRejectedLineItem,
): value is PurchaseRequestLineItem {
  return "averageNetPayablePerUnit" in value;
}
interface TextWithInfoProps {
  label: string;
  tooltipText: string;
  priceText?: string;
  boldLabel?: boolean;
  currency: Currency;
}
const TextWithInfo = ({ label, tooltipText, priceText, currency, boldLabel = false }: TextWithInfoProps) => {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant={boldLabel ? "subtitle1Bold" : "body1"} color="common.grey6">
          {label}
        </Typography>
        <InfoTooltip title={tooltipText} />
      </Stack>
      {priceText && (
        <CurrencyRenderer variant="subtitle1Bold" color="common.grey6" value={priceText} currency={currency} />
      )}
    </Stack>
  );
};
