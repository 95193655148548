import { LocalDate } from "adl-gen/common";
import {
  NumberOfUnits,
  PurchaseRequestDeliveryOption,
  PurchaseRequestState,
  PurchaseRequestStateEvent,
} from "adl-gen/ferovinum/app/db";
import { formatDate } from "utils/date-utils";
import { toPrecision } from "utils/numeric-utils";
import { PurchaseRequestConfirmationData } from "../ui/page/organisation/purchase-requests/organisation-purchase-request-confirmation/organisation-purchase-request-confirmation-page";
import { assertNever } from "utils/hx/util/types";

export function getPurchaseRequestStateDescription({
  stateEvents,
  collectionDays,
  purchaseRequestDeliveryOption,
  purchaserPaidDate,
  orgNetReceivablePaymentDate,
  collectedDate,
  deliveredDate,
  productionOrderNumber,
}: {
  stateEvents: PurchaseRequestStateEvent[];
  collectionDays: number;
  purchaseRequestDeliveryOption: PurchaseRequestDeliveryOption;
  purchaserPaidDate: LocalDate | null;
  orgNetReceivablePaymentDate: LocalDate | null;
  collectedDate: LocalDate | null;
  deliveredDate: LocalDate | null;
  productionOrderNumber?: string;
}) {
  const lastStateEvent = stateEvents[stateEvents.length - 1];
  const { state, time } = lastStateEvent;
  const eventDate = formatDate(new Date(time));
  const isCollection = purchaseRequestDeliveryOption.kind === "collectFromStorageLocation";
  switch (state) {
    case "new":
      return `Request made on ${eventDate}`;
    case "purchaserAccepted":
    case "productionOrderComplete":
      return `Accepted by the purchaser on ${eventDate}`;
    case "readyForCollection":
      return `Marked as ready for collection on ${eventDate}`;
    case "collected":
      return `Collected on ${collectedDate ? formatDate(new Date(collectedDate)) : eventDate}`;
    case "delivered":
      return `${isCollection ? "Collected" : "Delivered"} on ${
        deliveredDate ? formatDate(new Date(deliveredDate)) : eventDate
      }`;
    case "purchaserInvoiced":
      return `Purchaser invoiced on ${eventDate}`;
    case "purchaserPaid":
      return `Purchaser paid ${purchaserPaidDate ? "on " + formatDate(new Date(purchaserPaidDate)) : ""}`;
    case "organisationPaid":
      return `You were paid ${
        orgNetReceivablePaymentDate ? "on " + formatDate(new Date(orgNetReceivablePaymentDate)) : ""
      }`;
    case "purchaserRejected":
      return `Sale order was rejected by purchaser on ${eventDate}`;
    case "expired":
      return getPurchaseRequestLongExpiryReason(stateEvents, collectionDays, productionOrderNumber);
    default:
      assertNever(state);
  }
}

function getPurchaseRequestLongExpiryReason(
  prStates: PurchaseRequestStateEvent[],
  collectionDays: number,
  productionOrderNumber?: string,
) {
  const stateEventBeforeExpiry = [...prStates].reverse()[1];
  const { state, time } = stateEventBeforeExpiry;
  const date = formatDate(new Date(time));

  let expiryReason = `Expired on ${date} as `;
  switch (state) {
    case "new": {
      expiryReason += "purchaser did not approve by required date";
      break;
    }
    case "purchaserAccepted": {
      if (productionOrderNumber !== undefined) {
        expiryReason += `production order ${productionOrderNumber} was rejected or could not be completed in time`;
      } else {
        expiryReason += "stock preparation took longer than expected";
      }
      break;
    }
    case "readyForCollection": {
      expiryReason += `stock was not collected within ${collectionDays} days`;
      break;
    }
  }

  return expiryReason;
}

export function getPurchaseRequestShortExpiryReason(prStates: PurchaseRequestStateEvent[], collectionDays: number) {
  const lastNonExpiredEvents = [...prStates].reverse()[1];

  let expiryReason = "Expired: ";
  switch (lastNonExpiredEvents.state) {
    case "new":
      expiryReason += "missed approval deadline";
      break;
    case "purchaserAccepted":
      expiryReason += "preparation took longer than stated";
      break;
    case "readyForCollection":
      expiryReason += `was not collected within ${collectionDays} days`;
      break;
  }
  return expiryReason;
}

export function getIncoterms(
  purchaseRequestData: Pick<PurchaseRequestConfirmationData, "purchaseRequestDeliveryOption">,
) {
  return purchaseRequestData.purchaseRequestDeliveryOption.kind === "collectFromStorageLocation"
    ? purchaseRequestData.purchaseRequestDeliveryOption.value.incoterms
    : purchaseRequestData.purchaseRequestDeliveryOption.value.incoterms;
}

const finalStates: PurchaseRequestState[] = ["purchaserPaid", "purchaserRejected", "expired", "organisationPaid"];
export function isPurchaseRequestFinalState(state: PurchaseRequestState) {
  return finalStates.includes(state);
}

export function formatLineItemDiscountPct(
  numUnits: NumberOfUnits,
  price: number | string,
  discountVal: number | string,
) {
  const priceNum = typeof price === "string" ? Number(price) : price;
  return formatDiscountPct(numUnits.value * priceNum, discountVal);
}

export function formatDiscountPct(totalVal: number | string, discountVal: number | string): string {
  const totalNum = typeof totalVal === "string" ? Number(totalVal) : totalVal;
  const discountNum = typeof discountVal === "string" ? Number(discountVal) : discountVal;

  const discountPct = (discountNum / totalNum) * 100;
  const discountPct2dp = toPrecision(discountPct, 2);
  return (discountPct != Number(discountPct2dp) ? "~" : "") + discountPct2dp;
}
