import { Currency } from "adl-gen/ferovinum/app/db";
import { pick } from "lodash";
import { BigDecimal } from "adl-gen/common";

interface RoundedCurrencyValue {
  kind: "rounded";
  minimumFractionDigits: number;
  maximumFractionDigits: number;
}

interface NonRoundedCurrencyValue {
  kind: "not-rounded";
}
export interface CurrencyFormatterArgs {
  rounding?: RoundedCurrencyValue | NonRoundedCurrencyValue;
  currency?: Currency;
}

const defaultCurrencyRounding: RoundedCurrencyValue = {
  kind: "rounded",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export const createBigDecimalCurrencyFormatter = (defaultCurrency: Currency): BigDecimalCurrencyFormatter => {
  const formatter = createCurrencyFormatter(defaultCurrency);
  return (args?: CurrencyFormatterArgs) => ({
    format: (bigDecimal: BigDecimal) => {
      // Note: Number("") = 0  (we want to call this behaviour out if it happens)
      if (bigDecimal === undefined || bigDecimal.trim().length === 0) {
        return "NaN";
      }
      return formatter(args).format(Number(bigDecimal));
    },
  });
};

export const createCurrencyFormatter =
  (defaultCurrency: Currency): CurrencyFormatter =>
  (args: CurrencyFormatterArgs = {}) => {
    const { rounding = defaultCurrencyRounding, currency = defaultCurrency } = args;
    const roundingParams =
      rounding.kind === "rounded" ? pick(rounding, "maximumFractionDigits", "minimumFractionDigits") : {};
    return new Intl.NumberFormat("en-US", {
      currency,
      style: "currency",
      ...roundingParams,
    });
  };

export const parsePrice = (value: string): number | null => {
  if (!value) return null;

  let sanitisedValue = value.trim();

  sanitisedValue = sanitisedValue.replace(/[^0-9.,eE+-]/g, "");
  sanitisedValue = sanitisedValue.replace(/,/g, ".");

  const parsedNumber = Number(sanitisedValue);

  return isNaN(parsedNumber) ? null : parsedNumber;
};

export type CurrencyFormatter = (args?: CurrencyFormatterArgs) => { format(val: number): string };
export type BigDecimalCurrencyFormatter = (args?: CurrencyFormatterArgs) => { format(val: BigDecimal): string };
