import { Many, PropertyName, omit, pick } from "lodash";
import { ObjectSchema, Schema } from "yup";
import { DataFieldDef } from "./data-field/data-field-def";
import {
  ObjectFields,
  makeObjectBaseSchema,
  makeObjectSchema,
  mapObjectDef,
  mapObjectDefToArray,
  pickData,
} from "./data-field/object-field-def";

type RowValidator<T extends object> = (row: T, labels: { [K in keyof T]: string }) => string[] | string | undefined;

export interface CsvRowParserResult<T extends object> {
  value?: T;
  errors: string[];
}

// The purpose of CsvRowParser is to parse a row of CSV data into an object of type T.
// It is also able to validate the data against a schema.
// It also provide utilities such as extracting raw data from a row, and mapping row data to an array.
export class CsvRowParser<T extends object> {
  public static make<S extends object>(
    objectDef: Readonly<ObjectFields<S>>,
    headers: Readonly<string[]>,
    rowValidator?: RowValidator<S>,
  ): CsvRowParser<S> {
    const colIdxLookup = mapObjectDef(objectDef, ({ label }) => {
      return headers.findIndex(h => {
        return h && h.toLowerCase().startsWith(label.toLowerCase());
      });
    });
    return new CsvRowParser<S>(Object.freeze({ ...objectDef }), Object.freeze({ ...colIdxLookup }), rowValidator);
  }

  private readonly baseSchema: ObjectSchema<T>;
  private readonly fullSchema: ObjectSchema<T>;

  private constructor(
    private readonly objectDef: Readonly<ObjectFields<T>>,
    private readonly colIdxLookup: Readonly<{ [K in keyof T]: number }>,
    private readonly rowValidator?: RowValidator<T>,
  ) {
    this.baseSchema = makeObjectBaseSchema(objectDef);
    this.fullSchema = makeObjectSchema(objectDef);
  }

  // Some times we are only intereted in a subset of properties / columns.
  // This method creates a new CsvRowParser by omiting specified keys
  omit<K extends PropertyName[]>(...keys: K): CsvRowParser<Pick<T, Exclude<keyof T, K[number]>>> {
    return new CsvRowParser<Pick<T, Exclude<keyof T, K[number]>>>(
      omit(this.objectDef, ...keys),
      omit(this.colIdxLookup, ...keys),
    );
  }

  // Sometimes we are only intereted in a subset of properties / columns.
  // This method creates a new CsvRowParser by picking the specified keys
  pick<U extends keyof T>(...keys: Array<Many<U>>): CsvRowParser<Pick<T, U>> {
    return new CsvRowParser<Pick<T, U>>(pick(this.objectDef, ...keys), pick(this.colIdxLookup, ...keys));
  }

  validate(rowData: Readonly<string[]>): CsvRowParserResult<T> {
    const stringValues = mapObjectDef(this.objectDef, (_, path) => this.getRawData(rowData, path));
    const validatorErrors = this.runRowValidator(stringValues);
    // run the full validation
    try {
      const value = this.fullSchema.validateSync(stringValues, { abortEarly: false }) as T;
      return {
        // casting strips away all properties with 'undefined' values, so we need to add them back
        value: mapObjectDef(this.objectDef, (_, key) => value[key]),
        errors: validatorErrors,
      };
    } catch (e: unknown) {
      return { errors: [...extractErrors(e), ...validatorErrors] };
    }
  }

  private runRowValidator(stringValues: { [P in keyof T]: string }): string[] {
    const errors: string[] = [];
    if (this.rowValidator) {
      try {
        const rowValue = this.baseSchema.validateSync(stringValues, { abortEarly: false }) as T;
        const labels = mapObjectDef(this.objectDef, def => def.label);
        const errorResult = this.rowValidator(rowValue, labels);
        if (typeof errorResult === "string") {
          errors.push(errorResult);
        } else if (Array.isArray(errorResult)) {
          errors.push(...errorResult);
        }
      } catch {}
    }
    return errors;
  }

  checkForErrors(rowData: Readonly<string[]>): string[] {
    return this.validate(rowData).errors;
  }

  parse(rowData: Readonly<string[]>): T {
    const res = this.validate(rowData);
    if (res.errors.length > 0) {
      throw new Error("Validation failed with errors: " + res.errors.join(", "));
    } else if (res.value) {
      return res.value;
    } else {
      throw new Error("Value not present (this should not happen)");
    }
  }

  makeSchemaOverride(
    schemaOverride: <K extends keyof T>(def: DataFieldDef<T[K]>, key: K) => Schema<T[K]>,
    excludes?: readonly [string, string][],
  ): ObjectSchema<T> {
    return makeObjectSchema(this.objectDef, schemaOverride, excludes);
  }

  getColIdx(key: keyof T): number {
    return this.colIdxLookup[key];
  }

  getRawData(rowData: Readonly<string[]>, key: keyof T): string {
    const colIdx = this.colIdxLookup[key];
    return rowData[colIdx] ?? "";
  }

  castData<K extends keyof T>(rowData: Readonly<string[]>, key: K): T[K] | undefined {
    const rawData = this.getRawData(rowData, key);
    try {
      const castData = this.objectDef[key].cast(rawData);
      return castData;
    } catch (e) {
      return undefined;
    }
  }

  mapRowDataToArray<R>(
    rowData: Readonly<string[]>,
    mapFn: <K extends keyof T>(value: string, key: K, def: DataFieldDef<T[K]>) => R,
  ): R[] {
    return mapObjectDefToArray(this.objectDef, (_, key) => {
      return mapFn(this.getRawData(rowData, key), key, this.objectDef[key]);
    });
  }

  // Pick only the properties given by the objectDef from a potentially larger object
  pickData<S extends T>(data: S): T {
    return pickData(this.objectDef, data);
  }
}

function extractErrors(e: unknown) {
  if (typeof e === "object" && e !== null && "errors" in e && Array.isArray(e.errors)) {
    return e.errors;
  } else {
    return ["An unknown error occurred."];
  }
}
