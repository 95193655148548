import React, { useState } from "react";
import { Stack, Alert, Typography } from "@mui/material";
import { GridComparatorFn } from "@mui/x-data-grid";
import { PurchaseRequestProgressStepper } from "components/widgets/base-progress-stepper/purchase-request-progress-stepper";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { AppRoutes } from "../../../../../app/app-routes";
import { createBigDecimalCurrencyFormatter } from "utils/currency-utils";
import { formatLocalDate } from "utils/date-utils";
import { WithDbId } from "adl-gen/common/db";
import { NominatedPurchaserDetails, PurchaseRequestView } from "adl-gen/ferovinum/app/api";
import { NominatedPurchaserTerms, Organisation, Currency, PurchaseRequestState } from "adl-gen/ferovinum/app/db";
import { LoadingBackdrop } from "components/widgets/loader/loading-backdrop";
import { GridTable, GridTableColumnFactory } from "components/widgets/grid-table/grid-table";
import { GridSortModel } from "@mui/x-data-grid";
import { PurchaseRequestsDashboardSection } from "./organisation-purchase-requests-page";
import { LoadingActionButton } from "components/widgets/buttons/loading-action-button/loading-action-button";
import { TabbedPage } from "components/library/widgets/tabbed-page";
import { Box } from "@mui/system";
import { Download, OpenInNew } from "@mui/icons-material";
import { UploadProductSalePricesButton } from "./upload-product-sale-price-components";

export interface CreditModalValues {
  organisations: WithDbId<Organisation>[];
  nominatedPurchaserDetails: NominatedPurchaserDetails[];
  nominatedPurchaserTerms: WithDbId<NominatedPurchaserTerms>[];
}

export interface PurchaseRequestsPageViewProps {
  inProgressPurchaseRequests: PurchaseRequestView[];
  completedPurchaseRequests: PurchaseRequestView[];
  onSelectSection: (section: PurchaseRequestsDashboardSection) => void;
  completedPurchaseRequestsLoaded: boolean;
  onDownloadCSV: () => void;
}

const nextStateDeadlineComparator: GridComparatorFn = (v1, v2) => {
  const date1 = new Date(v1 as string);
  const date2 = new Date(v2 as string);
  if (isNaN(date1.getTime())) return 1;
  if (isNaN(date2.getTime())) return -1;
  return date1.getTime() - date2.getTime();
};

interface InProgressTableProps {
  inProgressPurchaseRequests: PurchaseRequestView[];
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
}

interface InProgressRowData {
  purchaseRequestRef: string;
  purchaseRequestId: string;
  purchaser: string;
  purchaserInvoiceAmount: number;
  purchaserInvoiceDueDate: string;
  currency: Currency;
  progress: React.JSX.Element;
  nextState: PurchaseRequestState | null;
  nextStateDeadline: string | null;
}

const InProgressTable = ({ inProgressPurchaseRequests, sortModel, onSortModelChange }: InProgressTableProps) => {
  const inProgressRowData: InProgressRowData[] = inProgressPurchaseRequests.map(prView => {
    const pr = prView.purchaseRequest.value;
    return {
      purchaseRequestRef: pr.purchaseRequestNumber,
      purchaseRequestId: prView.purchaseRequest.id,
      purchaser: prView.purchaser.value.name,
      purchaserInvoiceAmount: parseFloat(prView.purchaserInvoiceAmount),
      purchaserInvoiceDueDate: formatLocalDate(prView.invoiceDueDate),
      currency: pr.purchaserCurrency,
      progress: (
        <PurchaseRequestProgressStepper
          upcomingStateDeadline={prView.nextStateDeadline ?? undefined}
          currentState={pr.stateEvents[pr.stateEvents.length - 1].state}
          lastStateSet={pr.stateEvents[pr.stateEvents.length - 1].time}
        />
      ),
      nextState: prView.nextState,
      nextStateDeadline: prView.nextStateDeadline,
    };
  });

  const columnFactory = new GridTableColumnFactory<InProgressRowData>();
  const columns = [
    columnFactory.makeHyperLinkColumn({
      header: "Reference No.",
      name: "purchaseRequestRef",
      hrefGetter: row => `${AppRoutes.OrganisationPurchaseRequestDetails}/${row.purchaseRequestId}`,
      getter: row => row.purchaseRequestRef,
      icon: OpenInNew,
    }),
    columnFactory.makeTextColumn({
      header: "Purchaser",
      name: "purchaser",
      getter: row => row.purchaser,
    }),
    columnFactory.makeProgressStepperColumn({
      header: "Progress",
      key: "progress",
      align: "center",
      flex: 3,
      sortComparator: nextStateDeadlineComparator,
    }),
    columnFactory.makeDateColumn({
      header: "Invoice Due Date",
      key: "purchaserInvoiceDueDate",
      displayFormatter: row => (
        <Stack alignItems="center">
          {row.nextState && row.nextStateDeadline && (
            <Typography variant="caption">{formatLocalDate(row.purchaserInvoiceDueDate)}</Typography>
          )}
        </Stack>
      ),
    }),
    columnFactory.makeNumberColumn({
      header: "Invoice Amount",
      key: "purchaserInvoiceAmount",
      displayFormatter: row => (
        <Stack alignItems="center">
          {createBigDecimalCurrencyFormatter(row.currency)().format(row.purchaserInvoiceAmount.toString())}
        </Stack>
      ),
    }),
  ];

  return (
    <GridTable
      columns={columns}
      inputRowsField={inProgressRowData}
      tableHeight="auto"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      searchField
      rowHeight={150}
    />
  );
};

interface CompletedTableProps {
  completedPurchaseRequests: PurchaseRequestView[];
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
}

interface CompletedRowData {
  purchaseRequestRef: string;
  purchaseRequestId: string;
  purchaser: string;
  purchaserInvoiceAmount: number;
  time: string;
  currency: Currency;
}

const CompletedTable = ({ completedPurchaseRequests, sortModel, onSortModelChange }: CompletedTableProps) => {
  const completedRowsData: CompletedRowData[] = completedPurchaseRequests.map(prView => ({
    purchaseRequestRef: prView.purchaseRequest.value.purchaseRequestNumber,
    purchaseRequestId: prView.purchaseRequest.id,
    purchaser: prView.purchaser.value.name,
    purchaserInvoiceAmount: parseFloat(prView.purchaserInvoiceAmount),
    time: formatLocalDate(
      new Date(prView.purchaseRequest.value.stateEvents[prView.purchaseRequest.value.stateEvents.length - 1].time)
        .toISOString()
        .split("T")[0],
    ),
    currency: prView.purchaseRequest.value.purchaserCurrency,
  }));

  const columnFactory = new GridTableColumnFactory<CompletedRowData>();
  const columns = [
    columnFactory.makeHyperLinkColumn({
      header: "Reference No.",
      name: "purchaseRequestRef",
      hrefGetter: row => `${AppRoutes.OrganisationPurchaseRequestDetails}/${row.purchaseRequestId}`,
      getter: row => row.purchaseRequestRef,
      icon: OpenInNew,
    }),
    columnFactory.makeTextColumn({
      header: "Purchaser",
      name: "purchaser",
      getter: row => row.purchaser,
    }),
    columnFactory.makeTextColumn({
      header: "Invoice Amount",
      name: "purchaserInvoiceAmount",
      getter: row => createBigDecimalCurrencyFormatter(row.currency)().format(row.purchaserInvoiceAmount.toString()),
    }),
    columnFactory.makeDateColumn({
      header: "Completion Date",
      key: "time",
      displayFormatter: row => formatLocalDate(row.time),
    }),
  ];

  return (
    <GridTable
      columns={columns}
      inputRowsField={completedRowsData}
      tableHeight="auto"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      searchField
    />
  );
};

export const PurchaseRequestsPageView = ({
  inProgressPurchaseRequests,
  completedPurchaseRequests,
  onSelectSection,
  completedPurchaseRequestsLoaded,
  onDownloadCSV,
}: PurchaseRequestsPageViewProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "time", sort: "desc" }]);

  const handleDownloadCSV = async () => onDownloadCSV();

  const hasInProgressPurchaseRequests = inProgressPurchaseRequests.length > 0;
  const hasCompletedPurchaseRequests = completedPurchaseRequests.length > 0 && completedPurchaseRequestsLoaded;

  const CreateReportDownloadButton = () => (
    <LoadingActionButton variant="outlined" onClick={handleDownloadCSV} startIcon={<Download />}>
      Download Detailed Report (CSV)
    </LoadingActionButton>
  );

  return (
    <PortalPageContent header={<PortalPageContentHeader title="Third Party Sales" />}>
      <TabbedPage
        defaultTab="inProgress"
        handleTabChange={({ key }) => onSelectSection(key as PurchaseRequestsDashboardSection)}
        tabs={[
          {
            label: "In Progress",
            key: "inProgress",
            content: (
              <Box>
                {hasInProgressPurchaseRequests ? (
                  <InProgressTable
                    inProgressPurchaseRequests={inProgressPurchaseRequests}
                    sortModel={sortModel}
                    onSortModelChange={(newModel: GridSortModel) => setSortModel(newModel)}
                  />
                ) : (
                  <Alert severity="info">There are currently no purchase requests in progress</Alert>
                )}
              </Box>
            ),
            toolbar: (
              <Stack spacing={1} direction="row">
                <CreateReportDownloadButton />
                <UploadProductSalePricesButton />
              </Stack>
            ),
          },
          {
            label: "Completed",
            key: "completed",
            content: (
              <Box>
                {hasCompletedPurchaseRequests ? (
                  <CompletedTable
                    completedPurchaseRequests={completedPurchaseRequests}
                    sortModel={sortModel}
                    onSortModelChange={(newModel: GridSortModel) => setSortModel(newModel)}
                  />
                ) : completedPurchaseRequestsLoaded ? (
                  <Alert severity="info">There are currently no completed purchase requests</Alert>
                ) : (
                  <LoadingBackdrop open={!completedPurchaseRequestsLoaded} />
                )}
              </Box>
            ),
            toolbar: <CreateReportDownloadButton />,
          },
        ]}
      />
    </PortalPageContent>
  );
};
