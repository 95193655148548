import { assertNever, assertNotUndefined } from "utils/hx/util/types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UpdatePurchaseRequestResp } from "adl-gen/ferovinum/app/api";
import { PurchaseRequest, PurchaserId } from "adl-gen/ferovinum/app/db";
import { LoggedInContext } from "../../../../../app/app";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { useAppService } from "../../../../../hooks/use-app-service";
import { useLoadingDataState } from "utils/hooks/use-loading-data";
import { useNominatedPurchaserIsConfigured } from "../../../../../hooks/use-nominated-purchaser-is-configured";
import { useSelectedOrgId } from "../../../../layouts/portal-page-layout/portal-page";
import { Loader } from "components/widgets/loader/loader";
import { isLoaded, loaded, loading, LoadingValue } from "utils/utility-types";
import { OrganisationPurchaseRequestsPageView } from "./organisation-purchase-requests-by-purchaser-page-view";
import { DbKey, WithDbId } from "adl-gen/common/db";
import { BigDecimal, LocalDate } from "adl-gen/common";
import { isAdmin } from "utils/user-utils";
import { formatDateToLocalDate } from "utils/date-utils";

export type PurchaseRequestAdminAction = MarkCollected | MarkDelivered | MarkPurchaserPaid;
interface MarkCollected {
  action: "markCollected";
  collectedAt: LocalDate;
}

interface MarkDelivered {
  action: "markDelivered";
  deliveredAt: LocalDate;
}

interface MarkPurchaserPaid {
  action: "markPurchaserPaid";
  paidAt: Date;
  fxRate: BigDecimal | null;
}

export const OrganisationPurchaseRequestsByPurchaserPage = () => {
  const service = useAppService();
  const selectedOrgId = assertNotUndefined(useSelectedOrgId());
  const loggedInUserContext = useContext(LoggedInContext);
  const [showAlert] = useAlert();
  const nominatedPurchaserFeeConfig = useNominatedPurchaserIsConfigured();

  const [activeNominatedPurchaser, setActiveNominatedPurchaser] = useState<PurchaserId>();

  const loadNominatedPurchasers = useCallback(async () => {
    const resp = await service.getNominatedPurchasersForOrg({ organisationId: selectedOrgId });
    const sortedPurchasers = resp.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()));
    if (sortedPurchasers.length > 0 && activeNominatedPurchaser === undefined) {
      setActiveNominatedPurchaser(sortedPurchasers[0].nominatedPurchaserId);
    }
    return sortedPurchasers;
  }, [activeNominatedPurchaser, selectedOrgId, service]);
  const [loadingNominatedPurchasers] = useLoadingDataState(loadNominatedPurchasers);

  const loadPurchaseRequests = useCallback(
    async (nominatedPurchaserId: DbKey<PurchaserId>) => {
      const resp = await service.getNominatedPurchaserPurchaseRequests({
        organisationId: selectedOrgId,
        nominatedPurchaserId,
      });
      setLoadingPurchaseRequests(loaded(resp));
    },
    [selectedOrgId, service],
  );
  const [loadingPurchaseRequests, setLoadingPurchaseRequests] = useState<LoadingValue<WithDbId<PurchaseRequest>[]>>(
    loading(),
  );

  // Because we want to load the purchase requests for the first nominated purchaser when the component mounts, we need to
  // load the nominated purchasers first, then load the purchase requests for the first nominated purchaser.
  useEffect(() => {
    if (activeNominatedPurchaser) {
      setLoadingPurchaseRequests(loading());
      void loadPurchaseRequests(activeNominatedPurchaser);
    }
  }, [activeNominatedPurchaser, loadPurchaseRequests]);

  const onUpdatePurchaseRequestState = useCallback(
    async (resp: UpdatePurchaseRequestResp) => {
      if (resp !== "success") {
        await showAlert({
          title: "Error occurred",
          body: "Purchase request is not in a valid state.",
        });
      } else {
        await loadPurchaseRequests(assertNotUndefined(activeNominatedPurchaser));
      }
    },
    [activeNominatedPurchaser, loadPurchaseRequests, showAlert],
  );

  const onMarkReadyForCollection = useCallback(
    async (id: DbKey<PurchaseRequest>) => {
      await onUpdatePurchaseRequestState(
        await service.organisationMarkPurchaseRequestReadyForCollection({
          purchaseRequestId: id,
        }),
      );
    },
    [onUpdatePurchaseRequestState, service],
  );

  const onAdminAction = useCallback(
    async (id: DbKey<PurchaseRequest>, adminAction: PurchaseRequestAdminAction) => {
      let resp: UpdatePurchaseRequestResp;
      if (adminAction.action === "markCollected") {
        resp = await service.markPurchaseRequestCollected({ purchaseRequestId: id, date: adminAction.collectedAt });
      } else if (adminAction.action === "markDelivered") {
        resp = await service.markPurchaseRequestDelivered({ purchaseRequestId: id, date: adminAction.deliveredAt });
      } else if (adminAction.action === "markPurchaserPaid") {
        // TODO (Nicole): Add user input to set this paidAt field
        resp = await service.adminMarkPurchaseRequestPurchaserPaid({
          purchaseRequestId: id,
          purchaserPaidDate: formatDateToLocalDate(adminAction.paidAt),
          fxRate: adminAction.fxRate,
        });
      } else {
        assertNever(adminAction);
      }

      await onUpdatePurchaseRequestState(resp);
    },
    [onUpdatePurchaseRequestState, service],
  );

  const getSummaryForPurchaseRequest = useCallback(
    async (id: DbKey<PurchaseRequest>) => {
      const breakdown = await service.thirdPartySalesSummaryById(id);
      if (breakdown.kind !== "success" && breakdown.kind !== "rejectedOrExpired") {
        await showAlert({
          severity: "error",
          title: "Error loading purchase request details",
          body: "Could not load purchase request details",
        });
        throw new Error("Couldn't load purchase request details");
      } else {
        return breakdown;
      }
    },
    [showAlert, service],
  );

  const useProfile = loggedInUserContext.loginState?.user?.profile;

  return (
    <Loader loadingStates={[loadingNominatedPurchasers]} fullScreen>
      {isLoaded(loadingNominatedPurchasers) && (
        <OrganisationPurchaseRequestsPageView
          isAdmin={useProfile !== undefined && isAdmin(useProfile)}
          activeNominatedPurchaser={activeNominatedPurchaser}
          purchaseRequests={loadingPurchaseRequests}
          nominatedPurchasers={loadingNominatedPurchasers.value}
          nominatedPurchaserConfig={nominatedPurchaserFeeConfig}
          onChangeNominatedPurchaser={setActiveNominatedPurchaser}
          onMarkReadyForCollection={onMarkReadyForCollection}
          onAdminAction={onAdminAction}
          getSummaryForPurchaseRequest={getSummaryForPurchaseRequest}
        />
      )}
    </Loader>
  );
};
